import React from "react"

import Layout from "../components/layout"
import { Col, Container, Row } from "react-bootstrap"
import { PageContainer } from "../components/PageContainer/styles";
import { Link } from "gatsby";

const ContactPageSuccess = () => {

  return (<Layout>
    <PageContainer>
      <Container>
        <Row>
          <Col xs={12} className="mb-5">
            <p className="mb-3">La tua richiesta è stata inviata con <strong class="text-success">successo</strong>!</p>
            <p>Sarai ricontattato/a nel minor tempo possibile.</p>
            <p className="mb-3">Puoi continuare la navigazione del sito o tornare alla <Link to="/">homepage</Link>.</p>
            <p>Grazie per averci contatto</p>
          </Col>
        </Row>
      </Container>
    </PageContainer>
  </Layout>)
}

export default ContactPageSuccess
